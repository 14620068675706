exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-carriage-js": () => import("./../../../src/pages/buy-carriage.js" /* webpackChunkName: "component---src-pages-buy-carriage-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politic-js": () => import("./../../../src/pages/politic.js" /* webpackChunkName: "component---src-pages-politic-js" */),
  "component---src-pages-sale-carriage-js": () => import("./../../../src/pages/sale-carriage.js" /* webpackChunkName: "component---src-pages-sale-carriage-js" */),
  "component---src-pages-sale-parts-js": () => import("./../../../src/pages/sale-parts.js" /* webpackChunkName: "component---src-pages-sale-parts-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

